#sidebar {

  width: 30%;
  max-width: 25em;
  min-width: 20em;

  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  background: #293a60c2;
  box-shadow: 8px 0px 15px 0px rgb(0 0 0 / 15%);

  justify-content: space-between;
  color: white;

  height: 100vh;
  margin: 0;
  font-family: "Inter", sans-serif;
}

#user-info h1 {
  font-size: 20px;
  margin: 0;
  padding: 10px;
  background-color: #bcced6;
  color: #4e4e4e;
  text-align: center;
}


.a-button {
  background-color: #00000000;
  cursor: pointer;
  border: none;
  color: white;
  font-size: 0.925rem;
  margin-bottom: .8em;

}

#item-list h2 {
  font-size: 18px;
  margin: 0;
  padding: 10px;
  background-color: #bcced6;
  color: #4e4e4e;
  text-align: center;
}

#item-list li {
  list-style: none;
  /* padding: 10px; */
  /* border-bottom: 1px solid #ccc; */
  justify-content: space-between;
}

#item-list li:last-child {
  border-bottom: none;
}



/* main window */
#main {
  /* width: 100%; */
  /* max-width: 800px; */
  /* height: 100%; */
  /* position: fixed; */
  /* top: 0; */
  /* right: 0; */
  /* background-color: #fff; */
  /* overflow-x: hidden; */
  /* display: flex; */
  /* flex-direction: column; */
  /* float: left; */
  /* display: table-cell; */
  vertical-align: middle;
  width: 75%;
  justify-content: center;
  /* height: 100vh; */
  align-items: center;
  /* text-align: center; */
}

/* chating window */
#chat-window {
  width: 50%;
  max-width: 550px;
  height: 50%;
  /* position: fixed; */
  top: 0;
  right: 0;
  background-color: #e6bdbd;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow:  20px 20px 60px #bebebe,
             -20px -20px 60px #ffffff;
}

/* chat-messages */
#chat-messages {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  /* padding: 10px; */
  /* border-color: aqua; */
  /* border-style: ridge; */
  /* background-color: beige; */
}

#chat-messages .message {
  margin-bottom: 10px;
}

body {

}


#item-list {
  height: 100%;
  background-color: #8d9bbb;
  margin: 0em 1em;
  border-radius: 1em;
  padding: 0 0 0 0;
}

.userKnowledgeDomain{
  height: 100%;
  display: flex;
  flex-direction: column;
}
#outer-container {
    display: flex;
    /* width: 100%; */
    height: 100%;
    overflow: hidden;
    /* padding: 10px 20px; */
    background-color: #e0e0e0;
    flex-direction: row;
}

#main-center {
  /* display: table-cell; */
  /* vertical-align: middle; */
  width: 100%;
  height: 100%;
  /* text-align: center; */
  /* background-color: aqua; */
  justify-content: center;
  display: flex;
  align-items: center;
}

#upload-form {
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    vertical-align: bottom;
    border: 4px dashed;
    border-color: #f0f0f0;
    position: relative;
    /* visibility: collapse; */
    /* color: grey; */
}

#btn-div {
  
/* position: absolute; */
  
bottom: 0;
  
/* left: 0; */
  
/* right: 0; */
  
/* display: block; */
  
/* width: inherit; */
}
.firebase-emulator-warning { display: none; }



#upload-form p{
  width: 100%;
  height: 100%;
  text-align: center;
  /* line-height: 170px; */
  color: #000000;
  font-family: Arial;
  /* align-content: space-around; */
  align-items: center;
}
#upload-form input{
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 65%;
  outline: none;
  opacity: 0;
}
#upload-form button{
  margin: 0;
  color: #4e4e4e;
  background: #f0f0f0;
  border: none;
  width: 100%;
  /* height: 35px; */
  /* margin-top: -20px; */
  /* margin-left: -4px; */
  border-radius: 4px;
  /* border-bottom: 4px solid #117A60; */
  transition: all .2s ease;
  outline: none;
}
#upload-form button:hover{
  background: #149174;
    color: #0C5645;
}
#upload-form button:active{
  border:0;
}

#progress-bar{
  width: 0%;
  height: 2em;
  background: #16a085;
  transition: width .2s ease;
  margin-top: 1em;
  margin-bottom: 1em;
}



.doc-list {
  padding-inline-start: .3em;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  background-color: #00223d6e;
  /* padding: 10px; */
  margin: 0.2em 1em;
  border-radius: .3em;
}
.grid-item {
  background-color: rgb(255 255 255 / 17%);
  border: 1px solid rgb(5 127 209 / 42%);
  padding: 10px;
  /* font-size: 30px; */
  text-align: center;
  justify-content: center;
  display: flex;
  margin: .2em;
  cursor: pointer;
}

.grid-item:hover {
  background-color: rgb(255 255 255 / 87%);
  color: #575757
}

#footer-logo {
  
/* width: 100%; */
  
/* padding: 2px 10px; */
  
/* background-color: #344260; */
  
display: flex;
  
justify-content: center;
  
margin-top: 2em;
  
margin-bottom: 2em;
}

#footer-logo img {
  width: 30%;
}

#sidebar-footer {
padding: 0 0 0 1.5em;
margin-top: 1em;
display: flex;
flex-direction: column;
align-items: flex-start;
}

#sidebar-footer div {
  margin-bottom: 1em;
}

#sidebar-footer a {
  color: #fafdff;
  text-decoration: none;
  font-size: .925rem;
  /* margin-bottom: 20.3em; */
}



/* Solid border */
hr.solid-divider {
  border-top: 1px solid #fffbfb;
  width: 60%;
  margin-top: 1em;
  margin-bottom: 1em;
  
}

#debug-div {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  background-color: #0000004a;
  padding: 1em;
  color: lime;
  border: 1px lime;
  font-size: 8px;
  width: 54em;
  visibility: visible;
  border-style: dashed;
}

#user-photo-img {
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
}

#user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0 1em;
}

#user-status {

}
#user-display-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #fafdff;
}

#user-photo {
  width: 33.33%;
  max-width: 5em;
}

#user-name{
  margin-left: 1em;
  width: 80%;
}

.sidebar-title {
  /* font-size: 1.5em; */
  font-weight: bold;
  color: #fafdff;
  margin-bottom: .8em;
  display: flex;
  justify-content: center;
}
.gold-text {
  color: #ded82a;
}

#upgrade-plan-btn {
  color: rgb(255, 255, 255);
  font-size: x-small;
  text-decoration: none;
  background-color: #00000000;
  border: none;
  cursor: pointer;
  
}

.plan-text{
  color: white;
  font-size: small;
  text-decoration: none;
}

#user-class {
  display: flex;
  justify-content:center;
  margin-bottom: .2em;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgb(0 0 0 / 64%); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15%auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
  max-width: 100em;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.sidebar-header-paid-status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5em;
  width: 100%;
}