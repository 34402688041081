.modal-grid-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2em 12em;
}


.modal-grid-row {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 1em;
}

.modal-grid-item {
    align-items: center;
    max-width: 50%;
    justify-content: center;
    display: flex;
    border: 1px solid #e0e0e0;
    margin: 0 1em;
    padding: 2em;
    flex: 1 100%;
    filter: grayscale(1);
    cursor: pointer;
}

.modal-grid-item:hover {
    filter: grayscale(0);
}

.modal-grid-item-enabled {
    border: 1px solid #54ff3e;
}
.modal-grid-img {
  width: 100%;
  height: 100%;
  object-fit: contain;

}
