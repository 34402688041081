
.login-body-all {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 12px;
  
}

.login-body {
  background-color: #fff9f9;
  min-height: 700px;
  min-width: 700px;
  height: 100vh;
  width: 100vw;
  background-color: hsl(0deg 0% 100%);
  background-image: radial-gradient(at 80% 0%, hsl(189deg 93% 72%) 0px, transparent 50%),
  radial-gradient(at 0% 50%, hsl(191deg 100% 96%) 0px, transparent 50%),
  radial-gradient(at 80% 50%, hsl(237deg 63% 79%) 0px, transparent 50%),
  radial-gradient(at 0% 100%, hsl(208deg 100% 72%) 0px, transparent 50%),
  radial-gradient(at 0% 0%, hsl(183deg 80% 86%) 0px, transparent 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.screenCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.login-section {
  margin: 0 auto;
  width: 400px;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(255,255,255,0.4);
  border-radius: 20px;
  box-shadow: 0px 0 31px 0px rgb(0 0 0 / 10%);
  padding: 20px;
  margin: 100px;
}

.login-h1 {
  margin: 20px 0 10px 0;
  font-size: 3rem;
  font-weight: 400;
  font-family: 'Verdana', sans-serif;
  color: #003ba15c;
}

.login-form {
  width: 70%;
  margin-bottom: 15px;
}

.login-h4 {
  margin: 20px 0 5px 0;
  font-size: 1.5rem;
  font-weight: 300;
  color: #003ba15c;
}
.login-input {
  width: 80%;
  font-size:1.1rem;
  font-weight: 300;
  padding: 7px 0 7px 10px;
  border: none;
  background-color: aliceblue;
  /* color: bisque; */
}

.username-input, .password-input {
  width: 90%;
  border-bottom: 1px solid #a4a4a4;
}

i {
  width: 10%;
  color: rgba(0,0,0,0.3);
  padding-right: 7px;
}

.login-form>p {
  width: 90%;
  font-size: 1.1rem;
  text-align: right;
  margin-top: 5px;
  font-weight: 300;
}

.login-btn {
  border: none;
  padding: 7px 20px;
  width: 50%;
  border-radius: 10px;
  font-size: .8rem;
  background-image: linear-gradient(43deg, hsl(218deg 100% 79%) 0%, hsl(189deg 82% 78%) 100%);
  color: white;
  font-weight: 600;
}

.logo{
  
display: flex;
  
width: 20%;
max-width: 350px;
align-items: center;
  
justify-content: center;
}

.img-logo {
  width: 100%;
  height: 100%;
}