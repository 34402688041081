.parent-list{
  padding-inline-start: 0%;
}

.doc-list {
  padding-inline-start: .3em;
}

.doc-list-div {
  display: flex;
  align-items: center;
}

.kb-item-checkbox {
  opacity: 40%;
}

.kb-item-list-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
}


.kb-item-list-container:hover {
  background-color: #ffffff42;
}

.kb-item-btn {
  opacity: 0%;
}


.kb-item-list-container:hover .kb-item-btn {
  opacity: 100%;
}

.kb-item-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
  font-size: small;
  text-transform: lowercase;
  align-items: center;
}


.kb-item-delete-btn {
  background-color: #ff777700;
  border: none;
  border-radius: 5px;
  color: #fe7171; 
  cursor: pointer; 
}

.kb-item-embedded {
  color: #6df3c6;
}

.kb-item-not-embedded {
  color: #fdf50c;
}

.kb-item-delete-btn:hover {
  color: #f93838;
}
